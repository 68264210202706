<template>
	<div>
		<el-form :inline="true" :model="param" ref="param" class="demo-form-inline">
			
			<el-form-item label="统计时间">
				<div class="x_v_center">
					<el-date-picker size="mini" v-model="beginTime" type="datetime" placeholder="开始时间" @change="getPickerTime('beginTime')"></el-date-picker>
					<div class="line_box">-</div>
					<el-date-picker size="mini" v-model="endTime" type="datetime" placeholder="结束时间" @change="getPickerTime('endTime')"></el-date-picker>
				</div>
			</el-form-item>
			
			<el-form-item>
				<el-button size="mini" type="primary" :loading="loadingBtn" @click="onSubmit">查询</el-button>
				<el-button size="mini" @click="resetForm('param')">重置</el-button>
			</el-form-item>
		</el-form>
		
		<div>
			<div class="money_box">收益汇总：{{allMoney || 0}}</div>
			<div class="money_box">直推用户充值金额汇总：{{userRecharge || 0}}(收益:{{userRechargeIncome || 0}})</div>
			<div class="money_box">主播推广用户充值金额汇总：{{anchorToUserRecharge || 0}}(收益:{{anchorToUserRechargeIncome || 0}})</div>
			
	
			
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				param: {
					beginTime: null,
					endTime: null
				},
				beginTime: null,
				endTime: null,
				loadingBtn:false,
				
				allMoney: null, // 	充值金额汇总
				userRecharge: null, // 	 直推用户充值
				anchorToUserRecharge: null, // 		主播推广用户充值
				userRechargeIncome: null, // 	直推用户充值 收益
				anchorToUserRechargeIncome: null, // 	主播推广充值 收益
			}
		},
		mounted() {
			this.getmyCpsMoneyInfo()
		},
		methods:{
			async getmyCpsMoneyInfo(){
				let that = this
				let param = {
					url: "myCpsMoneyInfo",
					data: this.param
				}
				await this.api(param).then(res => {
					if(res.status == 0){
						that.allMoney = res.data.allMoney
						that.userRecharge = res.data.userRecharge
						that.anchorToUserRecharge = res.data.anchorToUserRecharge
						that.userRechargeIncome = res.data.userRechargeIncome
						that.anchorToUserRechargeIncome = res.data.anchorToUserRechargeIncome
				
					}
				})
			},
			getPickerTime(name){
				console.log("name=",name,this.beginTime)
				
				if(this[name]){
					let timeParam = this.getTime(this[name])
					let time = timeParam.year + '-' + timeParam.month + '-' + timeParam.day + ' ' + timeParam.h + ':' + timeParam.m + ':' + timeParam.s
					this.param[name] = time
					console.log("this.param",this.param)
				}else{
					this.param[name] = null
				}
			},
			async onSubmit() {
				console.log('submit!');
				this.loadingBtn = true
				await this.getmyCpsMoneyInfo()
				this.loadingBtn = false
			},
			resetForm(formName) {
				console.log("resetForm")
				this.$refs[formName].resetFields();
				this.beginTime = null  //  	开始时间
				this.endTime = null  //  	结束时间
				this.param.beginTime = null  //  	开始时间
				this.param.endTime = null  //  	结束时间
			},
		}
	}
</script>

<style scoped="">
	.money_box{
		height: 30px;
		line-height: 30px;
		padding-left: 10px;
		margin-bottom: 10px;
		font-size: 15px;
		font-weight: bold;
	}
</style>
